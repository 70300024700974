<template>
  <div class="v-notice">
    <van-swipe
      vertical
      :show-indicators="false"
      class="notice-swipe"
      :autoplay="autoplay"
    >
      <van-swipe-item
        v-for="(item, index) in list"
        :key="index"
        @click="toEmit(item)"
        >{{ item.topicTitle }}</van-swipe-item
      >
    </van-swipe>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";

Vue.use(Swipe);
Vue.use(SwipeItem);
export default {
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
    },
    autoplay: {
      type: Number,
    },
  },
  methods: {
    toEmit(item) {
      this.$emit("toEmit", item);
    },
  },
};
</script>

<style lang="less" scoped>
.v-notice {
  height: 100%;
  /deep/ .van-swipe-item {
    display: flex;
    align-items: center;
  }
}
</style>
