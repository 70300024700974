import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    noKeepAlive: [],
    communityId: "",
    userId: "",
    houseId: "",
    defOrgId: "",
    tenantId: "",
    openId: "",
    assetId: "",
    systemUserInfo: {}//老年通一体机用户信息
  },
  mutations: {
    setCommunityId(state, communityId) {
      state.communityId = communityId;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setHouseId(state, houseId) {
      state.houseId = houseId;
    },
    setDefOrgId(state, defOrgId) {
      state.defOrgId = defOrgId;
    },
    setTenantId(state, tenantId) {
      state.tenantId = tenantId;
    },
    setOpenId(state, openId) {
      state.openId = openId;
    },
    setAssetId(state, assetId) {
      state.assetId = assetId;
    },
    setSystemUserInfo(state, systemUserInfo) {
      state.systemUserInfo = systemUserInfo;
    },
    setNoKeepAlive(state, value) {
      state.noKeepAlive = Array.isArray(value) ? value : [];
    },
    addNoKeepAlive(state, value) {
      if (!Array.isArray(value)) {
        value = [value];
      }
      value.forEach(v => {
        !state.noKeepAlive.includes(v) && state.noKeepAlive.push(v);
      });
    },
    removeNoKeepAlive(state, value) {
      if (!Array.isArray(value)) {
        value = [value];
      }
      value.forEach(v => {
        const index = state.noKeepAlive.indexOf(v);
        index >= 0 && state.noKeepAlive.splice(index);
      });
    },
  },
  actions: {},
  modules: {},
});
