<template>
  <div>
    <van-popup
      v-model="show"
      @click-overlay="clickOverlay"
      :position="position"
    >
      <van-picker
        :show-toolbar="showToolbar"
        :columns="columns"
        @change="change"
        :default-index="defaultIndex"
        @cancel="cancel"
        :value-key="valueKey"
        @confirm="confirmPicker"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Picker, Popup } from "vant";
Vue.use(Picker);
Vue.use(Popup);
export default {
  watch: {
    valueShow(newValue, oldValue) {
      this.show = newValue;
    },
  },
  components: {},
  props: {
    showToolbar: {
      type: Boolean,
      default: true,
    },
    valueKey: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "bottom",
    },
    valueShow: {
      type: Boolean,
      default: false,
    },
    defaultIndex: {
      type: Number,
      default: 2,
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    confirmPicker(value) {
      this.$emit("confirmPicker", value);
    },
    clickOverlay() {
      this.$emit("clickOverlay");
    },
    cancel() {
      this.$emit("cancel");
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style></style>
