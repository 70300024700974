const routes = [
  {
    path: "/futureTreat/tingTreatDetail",
    name: "tingTreatDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/tingTreat/tingTreatDetail.vue"], resolve),
  },
  //亭下治
  {
    path: "/futureTreat/commentRepresentative",
    name: "commentRepresentative",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/commentRepresentative.vue",
      ], resolve),
  },
  {
    path: "/futureTreat/commentRule",
    name: "commentRule",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/tingTreat/commentRule.vue"], resolve),
  },
  {
    path: "/futureTreat/tingTreat",
    name: "tingTreat",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/tingTreat/index.vue"], resolve),
  },
  //亭下领
  {
    path: "/futureTreat/tingXiaL",
    name: "tingXiaL",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/tingTreat/tingXiaL/index.vue"], resolve),
  },
  {
    path: "/futureTreat/tingXiaLDetail",
    name: "tingXiaLDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/tingXiaL/tingXiaLDetail.vue",
      ], resolve),
  },
  //亭下智
  {
    path: "/futureTreat/tingXiaZ",
    name: "tingXiaZ",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/tingTreat/tingXiaZ/index.vue"], resolve),
  },
  {
    path: "/futureTreat/tingXiaZComment",
    name: "tingXiaZComment",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/tingXiaZ/tingXiaZComment.vue",
      ], resolve),
  },
  {
    path: "/futureTreat/tingXiaZCDetail",
    name: "tingXiaZCDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/tingXiaZ/tingXiaZCDetail.vue",
      ], resolve),
  },
  {
    path: "/futureTreat/tingXiaZDetail",
    name: "tingXiaZDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/tingXiaZ/tingXiaZDetail.vue",
      ], resolve),
  },
  {
    path: "/futureTreat/organizaStr",
    name: "organizaStr",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/tingXiaZ/organizaStr.vue",
      ], resolve),
  },
  //亭下会
  {
    path: "/futureTreat/tingXiaH",
    name: "tingXiaH",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/tingTreat/tingXiaH/index.vue"], resolve),
  },
  {
    path: "/futureTreat/tingXiaHDetail",
    name: "tingXiaHDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/tingXiaH/tingXiaHDetail.vue",
      ], resolve),
  },
  {
    path: "/futureTreat/tingXiaHReport",
    name: "tingXiaHReport",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/tingXiaH/tingXiaHReport.vue",
      ], resolve),
  },
  {
    path: "/futureTreat/tingXiaHList",
    name: "tingXiaHList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/tingTreat/tingXiaH/tingXiaHList.vue",
      ], resolve),
  },

  //人大反应
  {
    path: "/futureTreat/peopleReactionTopicListElse",
    name: "peopleReactionTopicListElse",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/peopleReaction/peopleReactionTopicListElse.vue",
      ], resolve),
  },
  //人大反应详情
  {
    path: "/futureTreat/peopleReactionDetail",
    name: "peopleReactionDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/peopleReaction/peopleReactionDetail.vue",
      ], resolve),
  },
  //小亭议事
  {
    path: "/futureTreat/peopleReactionTopicList",
    name: "peopleReactionTopicList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/peopleReaction/peopleReactionTopicList.vue",
      ], resolve),
  },
  //小亭议事
  {
    path: "/futureTreat/peopleReactionFlow",
    name: "peopleReactionFlow",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/peopleReaction/peopleReactionFlow.vue",
      ], resolve),
  },
  //小亭议事
  {
    path: "/futureTreat/peopleReactionTopic",
    name: "peopleReactionTopic",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/peopleReaction/peopleReactionTopic.vue",
      ], resolve),
  },
  //民有所呼 我有所应 地图
  {
    path: "/futureTreat/businessNewMap",
    name: "businessNewMap",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/peopleReaction/businessNewMap.vue",
      ], resolve),
  },
  //民有所呼 我有所应
  {
    path: "/futureTreat/peopleReaction",
    name: "peopleReaction",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/peopleReaction/index.vue"], resolve),
  },
  //民有所呼 我有所应 登记反馈
  {
    path: "/futureTreat/peopleReactionForm",
    name: "peopleReactionForm",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/peopleReaction/peopleReactionForm.vue",
      ], resolve),
  },
  //民有所呼 我有所应 列表
  {
    path: "/futureTreat/peopleReactionList",
    name: "peopleReactionList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/peopleReaction/peopleReactionList.vue",
      ], resolve),
  },
  //防疫登记
  //首页
  {
    path: "/futureTreat/epidemicPrevent",
    name: "epidemicPrevent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/index.vue"], resolve),
  },
  //防疫资讯
  {
    path: "/futureTreat/epidemicNews",
    name: "epidemicNews",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicNews.vue",
      ], resolve),
  },
  //防疫登记
  {
    path: "/futureTreat/epidemicRegister",
    name: "epidemicRegister",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicRegister.vue",
      ], resolve),
  },
  //防疫登记
  {
    path: "/futureTreat/epidemicRes",
    name: "epidemicRes",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/epidemicRes.vue"], resolve),
  },
  /* 业主投票 STRART */
  // 首页
  {
    path: "/futureTreat/householdVote",
    name: "householdVote",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/index.vue"], resolve),
  },
  // 议事厅
  {
    path: "/futureTreat/discussionHall",
    name: "discussionHall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/householdVote/discussionHall.vue",
      ], resolve),
  },
  // 发起议题
  {
    path: "/futureTreat/addDiscussion",
    name: "addDiscussion",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/addDiscussion.vue"], resolve),
  },
  // 我的议题
  {
    path: "/futureTreat/myDiscussionList",
    name: "myDiscussionList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/householdVote/myDiscussionList.vue",
      ], resolve),
  },
  // 议题发起投票
  {
    path: "/futureTreat/initiateVote",
    name: "initiateVote",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/initiateVote.vue"], resolve),
  },
  // 议事厅
  {
    path: "/futureTreat/voteHall",
    name: "voteHall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/voteHall.vue"], resolve),
  },

  // 议事厅 - 议题上会
  {
    path: "/futureTreat/issueMeeting",
    name: "issueMeeting",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/issueMeeting.vue"], resolve),
  },

  // 议事厅 - 业主大会
  {
    path: "/futureTreat/ownersMeeting",
    name: "ownersMeeting",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/ownersMeeting.vue"], resolve),
  },
  // 议事厅 - 业主决策
  {
    path: "/futureTreat/ownerDecision",
    name: "ownerDecision",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/ownerDecision.vue"], resolve),
  },
  // 议事厅 - 投票详情
  {
    path: "/futureTreat/voteDetail",
    name: "voteDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/voteDetail.vue"], resolve),
  },
  // 议事厅 - 参加大会
  {
    path: "/futureTreat/joinHall",
    name: "joinHall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/joinHall.vue"], resolve),
  },
  // 议事厅 - 参加大会
  {
    path: "/futureTreat/voteResult",
    name: "voteResult",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/householdVote/voteResult.vue"], resolve),
  },

  /* 业主投票 END */
];

export default routes;
