const routes = [
  //  服务系统一体机-首页
  {
    path: "/serviceSystem/homePage",
    name: "homePage",
    component: (resolve) => require(["@/views/serviceSystem/homePage/index"], resolve),
  },
  //  服务系统一体机-注册
  {
    path: "/serviceSystem/register",
    name: "register",
    component: (resolve) => require(["@/views/serviceSystem/register/index"], resolve),
  },
  //身份证注册
  {
    path: "/serviceSystem/identityCardRegister",
    name: "identityCardRegister",
    component: (resolve) => require(["@/views/serviceSystem/identityCardRegister/index"], resolve),
  },
  //身份证信息-提交
  {
    path: "/serviceSystem/IdentitySubmission",
    name: "IdentitySubmission",
    component: (resolve) => require(["@/views/serviceSystem/identityCardRegister/IdentitySubmission"], resolve),
  },
  //人脸注册
  {
    path: "/serviceSystem/humanFaceRegister",
    name: "humanFaceRegister",
    component: (resolve) => require(["@/views/serviceSystem/humanFaceRegister/index"], resolve),
  },
  //人脸注册-提交
  {
    path: "/serviceSystem/faceSubmission",
    name: "faceSubmission",
    component: (resolve) => require(["@/views/serviceSystem/humanFaceRegister/faceSubmission"], resolve),
  },
  //手机号注册
  {
    path: "/serviceSystem/mobilePhoneRegister",
    name: "mobilePhoneRegister",
    component: (resolve) => require(["@/views/serviceSystem/mobilePhoneRegister/index"], resolve),
  },
  //导览页
  {
    path: "/serviceSystem/guidePage",
    name: "guidePage",
    component: (resolve) => require(["@/views/serviceSystem/guidePage/index"], resolve),
  },
  //我的信息
  {
    path: "/serviceSystem/myInformation",
    name: "myInformation",
    component: (resolve) => require(["@/views/serviceSystem/myInformation/index"], resolve),
  },
  //我要参加活动
  {
    path: "/serviceSystem/joinActivity",
    name: "joinActivity",
    component: (resolve) => require(["@/views/serviceSystem/joinActivity/index"], resolve),
  },
  //活动详情
  {
    path: "/serviceSystem/joinActivityActivityDetail",
    name: "joinActivityActivityDetail",
    component: (resolve) => require(["@/views/serviceSystem/joinActivity/joinActivityActivityDetail"], resolve),
  },
  //报名成功
  {
    path: "/serviceSystem/registrationSuccess",
    name: "registrationSuccess",
    component: (resolve) => require(["@/views/serviceSystem/joinActivity/registrationSuccess"], resolve),
  },
  //我的活动
  {
    path: "/serviceSystem/myActivity",
    name: "myActivity",
    component: (resolve) => require(["@/views/serviceSystem/myActivity/index"], resolve),
  },
  //我的活动详情
  {
    path: "/serviceSystem/myActivityDetail",
    name: "myActivityDetail",
    component: (resolve) => require(["@/views/serviceSystem/myActivity/myActivityDetail"], resolve),
  },
  //我的签到
  {
    path: "/serviceSystem/myCheckIn",
    name: "myCheckIn",
    component: (resolve) => require(["@/views/serviceSystem/myCheckIn/index"], resolve),
  },
  //我的签到详情
  {
    path: "/serviceSystem/myCheckInDetail",
    name: "myCheckInDetail",
    component: (resolve) => require(["@/views/serviceSystem/myCheckIn/myCheckInDetail"], resolve),
  },  
   //我要兑换
   {
    path: "/serviceSystem/myCovert",
    name: "myCovert",
    component: (resolve) => require(["@/views/serviceSystem/myCovert/index"], resolve),
  },
  //兑换详情
  {
    path: "/serviceSystem/myCovertDetail",
    name: "myCovertDetail",
    component: (resolve) => require(["@/views/serviceSystem/myCovert/myCovertDetail"], resolve),
  },  
  // 小亭食堂 - 餐品列表
  {
    path: "/serviceSystem/elderCanteen/mealsList",
    name: "ytjEldCanMealsList",
    meta: {
      keepAlive: true
    },
    component: (resolve) => require(["@/views/serviceSystem/elderCanteen/mealsList.vue"], resolve),
  },
  // 小亭食堂 - 确认下单
  {
    path: "/serviceSystem/elderCanteen/confirmPlaceOrder",
    name: "ytjEldCanConfirmPlaceOrder",
    component: (resolve) => require(["@/views/serviceSystem/elderCanteen/confirmPlaceOrder.vue"], resolve),
  },
  // 小亭食堂 - 下单结果
  {
    path: "/serviceSystem/elderCanteen/placeOrderResult",
    name: "ytjEldCanPlaceOrderResult",
    component: (resolve) => require(["@/views/serviceSystem/elderCanteen/placeOrderResult.vue"], resolve),
  },
  // 小亭食堂 - 我的订单
  {
    path: "/serviceSystem/elderCanteen/myOrderList",
    name: "ytjEldCanMyOrderList",
    component: (resolve) => require(["@/views/serviceSystem/elderCanteen/myOrderList.vue"], resolve),
  },
  // 小亭食堂 - 订单详情
  {
    path: "/serviceSystem/elderCanteen/myOrderDetail",
    name: "ytjEldCanMyOrderDetail",
    component: (resolve) => require(["@/views/serviceSystem/elderCanteen/myOrderDetail.vue"], resolve),
  },
  // 小亭食堂 - 无权限
  {
    path: "/serviceSystem/elderCanteen/noAuth",
    name: "ytjEldCanNoAuth",
    component: (resolve) => require(["@/views/serviceSystem/elderCanteen/noAuth.vue"], resolve),
  },
  // 每日签到
  {
    path: "/serviceSystem/dailySign",
    name: "DailySign",
    component: (resolve) => require(["@/views/serviceSystem/dailySign/index.vue"], resolve),
  },
];

export default routes;
