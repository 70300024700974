<template>
  <div class="layout-wrap">
    <layout-header />
    <div class="layout-main">
      <transition name="fade-move" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { getAllFaceUserInfo } from "./api.js";
import LayoutHeader from "./components/header";
import storage from "../utils/storage";
export default {
  name: 'serviceSystem',
  components: {
    LayoutHeader,
  },
  data() {
    return { timer: null, idleTimer: null };
  },
  created() {
    window.onFacePassRecognizedFailure = this.onFacePassRecognizedFailure;
    if (storage.get("systemUserInfo")) {
      const data = storage.get("systemUserInfo");
      this.$store.commit("setUserId", data.userId);
      this.$store.commit("setHouseId", data.houseId);
      this.$store.commit("setTenantId", data.tenantId);
      this.$store.commit("setCommunityId", data.communityId);
    }
  },
  mounted() {
    // 添加页面操作的事件监听
    this.startIdleTimer();
    window.addEventListener("mousemove", this.resetIdleTimer);
    window.addEventListener("keypress", this.resetIdleTimer);
    window.addEventListener("scroll", this.resetIdleTimer);
    window.addEventListener("click", this.resetIdleTimer);
    // this.getAllFaceUserInfo();
    //五分钟更新一次
    // this.timer = setInterval(() => {
    //   this.getAllFaceUserInfo();
    // }, 1000 * 60 * 5);
  },
  computed: {},

  methods: {
    onFacePassRecognizedFailure() {},
    startIdleTimer() {
      this.idleTimer = setInterval(() => {
        // 跳转到登录页面
        storage.remove();
        this.$router.replace({
          name: "homePage",
        });
      }, 1000 * 60 * 5);
    },
    resetIdleTimer() {
      clearInterval(this.idleTimer);
      this.startIdleTimer();
    },
    //获取所有的脸用户数据
    // getAllFaceUserInfo() {
    //   this.$axios.get(getAllFaceUserInfo).then((res) => {
    //     if (res.code == 200) {
    //       const { data } = res;
    //       this.loadFaceUserList(JSON.stringify(data));
    //     }
    //   });
    // },
    //人脸入库
    // loadFaceUserList(data) {
    //   window.sys.loadFaceUserList(data);
    // },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    // 移除事件监听以避免内存泄漏
    window.removeEventListener("mousemove", this.resetIdleTimer);
    window.removeEventListener("click", this.resetIdleTimer);
    window.removeEventListener("keypress", this.resetIdleTimer);
    window.removeEventListener("scroll", this.resetIdleTimer);
  },
};
</script>

<style lang="less">
.layout-wrap {
  width: 100%;
  height: 100%;
  background-image: url("./img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  overflow: hidden;
  .layout-main {
    width: 100%;
    height: calc(100% - 86px);
    box-sizing: border-box;
    > div {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
.fade-move-leave-active,
.fade-move-enter-active {
  transition: all 0.15s;
}
.fade-move-enter {
  opacity: 0;
  transform: translateX(-10px);
}
.fade-move-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
