<template>
  <div class="layout-header">
    <div class="header">
      <div class="header-l" @click="backHome">
        <img src="./img/home-icon.png" alt="" />
        <span>未来社区自助服务系统</span>
      </div>
      <div class="header-r">
        <span>{{ formattedDate }}</span>
        <span class="week">{{ weeks }}</span>
        <span class="icon">
          <img :src="getIcon(weatherData.weather)" alt="" />
        </span>
        <span class="temp">
          <span> {{ weatherData.temperature }}</span>
          <span>℃</span>
        </span>
        <span>{{ weatherData.weather }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "../../utils/storage.js";
import AMapLoader from "@amap/amap-jsapi-loader";
import moment from "moment";
window._AMapSecurityConfig = {
  securityJsCode: "14e8f6657d33d80943c81fd846b0735b",
};
export default {
  name: "layout-header",
  components: {},
  props: {},
  data() {
    return {
      weatherData: {}, //存放天气数据
      currentDate: moment(), // 初始化当前日期为Moment对象
      iconWeatherMap: {
        wind: [
          "有风",
          "平静",
          "微风",
          "和风",
          "清风",
          "强风/劲风",
          "疾风",
          "大风",
          "烈风",
          "风暴",
          "狂爆风",
          "飓风",
          "热带风暴",
          "龙卷风",
        ],
        cloudy: ["少云", "晴间多云", "多云"],
        snow: [
          "雪",
          "阵雪",
          "小雪",
          "中雪",
          "大雪",
          "暴雪",
          "小雪-中雪",
          "中雪-大雪",
          "大雪-暴雪",
          "冷",
        ],
        fog: [
          "浮尘",
          "扬沙",
          "沙尘暴",
          "强沙尘暴",
          "雾",
          "浓雾",
          "强浓雾",
          "轻雾",
          "大雾",
          "特强浓雾",
        ],
        sunny: ["晴", "热"],
        sleety: ["雨雪天气", "雨夹雪", "阵雨夹雪"],
        rain: [
          "阵雨",
          "雷阵雨",
          "雷阵雨并伴有冰雹",
          "小雨",
          "中雨",
          "大雨",
          "暴雨",
          "大暴雨",
          "特大暴雨",
          "强阵雨",
          "强雷阵雨",
          "极端降雨",
          "毛毛雨/细雨",
          "雨",
          "小雨-中雨",
          "中雨-大雨",
          "大雨-暴雨",
          "暴雨-大暴雨",
          "大暴雨-特大暴雨",
          "冻雨",
        ],
        overcast: ["阴", "霾", "中度霾", "重度霾", "严重霾", "未知"],
      },
    };
  },
  created() {},
  computed: {
    formattedDate() {
      return moment(this.currentDate).format("MM月DD日 HH:mm"); // 格式化日期
    },
    weeks() {
      const day = moment(this.currentDate).day();
      const formatWeek = {
        0: "星期天",
        1: "星期一",
        2: "星期二",
        3: "星期三",
        4: "星期四",
        5: "星期五",
        6: "星期六",
      };
      return formatWeek[day];
    },
  },
  mounted() {
    this.initAMap();
    this.timer = setInterval(() => {
      this.currentDate = moment();
    }, 1000);
  },
  methods: {
    //初始化数据
    initAMap() {
      let that = this;
      AMapLoader.load({
        key: "6ccc57a1965684bcf1d56bf846608b44", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.CitySearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        AMap.plugin("AMap.CitySearch", function () {
          var citySearch = new AMap.CitySearch();
          citySearch.getLocalCity(function (status, result) {
            if (status === "complete" && result.info === "OK") {
              // 查询成功，result即为当前所在城市信息
              console.log(result.city);
              //加载天气查询插件
              AMap.plugin("AMap.Weather", function () {
                //创建天气查询实例
                var weather = new AMap.Weather();

                //执行实时天气信息查询
                weather.getLive(result.city, function (err, data) {
                  console.log(err, data);
                  that.weatherData = data;
                  console.log("weatherData----->", that.weatherData);
                });
              });
            }
          });
        });
      });
    },
    //根据不同天气对应不同icon

    getIcon(weather) {
      //默认值
      let url = require("./img/sunny.png");
      for (const weatherKey in this.iconWeatherMap) {
        if (Object.hasOwnProperty.call(this.iconWeatherMap, weatherKey)) {
          const weatherNames = this.iconWeatherMap[weatherKey];
          const findWeatherItem = weatherNames.find((name) => weather === name);

          if (findWeatherItem) {
            url = require(`./img/${weatherKey}.png`);
            break;
          }
        }
      }

      return url;
    },
    //
    backHome() {
      storage.remove();
      this.$router.replace({
        name: "homePage",
      });
    },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.layout-header {
  box-sizing: border-box;
  width: 100%;
  .header {
    width: 100%;
    padding: 20px 26px 19px 47px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 6px solid;
    border-image: linear-gradient(to right, #8f41e9, #578aef) 1;
    .header-l {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      img {
        width: 40px;
        height: 40px;
        margin-right: 18px;
      }
      span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 30px;
        color: #ffffff;
        line-height: 42px;
        font-style: normal;
        background: linear-gradient(90deg, #3397fe 0%, #3666ef 100%);
        -webkit-background-clip: text; /* Safari/Chrome */
        -webkit-text-fill-color: transparent; /* Safari/Chrome */
      }
    }
    .header-r {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      span {
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        font-style: normal;
      }
      .week {
        margin-left: 24px;
        margin-right: 13px;
      }
      .icon {
        margin-right: 19px;
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }
      .temp {
        display: flex;
        justify-content: flex-start;
        margin-right: 13px;
        span {
          &:first-child {
            font-weight: 500;
            font-size: 24px;
            color: #333333;
            font-style: normal;
          }
        }
      }
    }
  }
}
</style>
