let storage = {
  set(key, value) {
    if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    window.sys.nativeSet(key, value);
  },
  get(key) {
    const data = window.sys.nativeGet(key);;
    try {
      return eval("(" + data + ")");
    } catch (err) {
      return data;
    }
  },
  remove() {
    window.sys.nativeClear()
  }

};

export default storage;
